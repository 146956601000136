import React from 'react';
import Features from 'views/Features';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';

const FeaturesPage = () => {
  return (
    <WithLayout
      component={Features}
      layout={Main}
    />
  )
};

export default FeaturesPage;
