import React from 'react';
import { useMediaQuery, Box, colors, Grid } from '@material-ui/core';
import { CardCategoryLink } from 'components/organisms';
import { useTheme } from '@material-ui/core/styles';
import { useStaticQuery, graphql } from 'gatsby';
import { filterByLocale } from 'utils/filterDataByLocale';

export default function Category() {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const data = useStaticQuery(graphql`
    query {
      features: allContentfulFeatures {
        edges {
          node {
            node_locale
            title
            body
          }
        }
      }
    }
  `);
  const filteredData = filterByLocale(data.features.edges);

  return (
    <div>
      <Grid container justify="center" spacing={isMd ? 4 : 2}>
        {filteredData.map((edge, index) => {
          return (
            <Grid container direction="column" xs={12} md={4}>
              <Box
                marginBottom={2}
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                padding={2}
                border="0px solid #ccc"
                borderRadius="4px"
              >
                <CardCategoryLink
                  title={edge.node.title}
                  subtitle={edge.node.body}
                  fontIconClass="fas fa-pen-nib"
                  color={colors.pink}
                  withShadow
                  key={index}
                  liftUp
                />
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
