import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Image } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';
import { graphql, useStaticQuery } from 'gatsby';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  image: {
    maxHeight: 400,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    },
  },
  textWhite: {
    color: 'white',
  },
  title: {
    fontWeight: 'bold',
  },
  section: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const Hero = props => {
  const data = useStaticQuery(graphql`
  query {
    pages: allContentfulPages {
      edges {
        node {
          title
          slug
        }
      }
    }
    header: allContentfulHeader(filter: {id: {eq: "38cff77d-1382-514b-80ae-6b2de2b77c7a"}}) {
      edges {
        node {
          title
          subTitle
          slug
          image{
            fluid(maxHeight: 600, maxWidth: 1400){
              src
            }
          }
          firstButton
          secondButton
        }
      }
    }
  }
`);
  const { className, ...rest } = props;
  const classes = useStyles();
  
  return (
    <div className={clsx(classes.root, className)} {...rest}>
      {data.header.edges.map((edge) => {
        const info = edge.node;
        return(
          
          <header>
              <Image
                src={info.image.fluid.src}
                srcSet={info.image.fluid.src}
                // srcSet="https://assets.maccarianagency.com/the-front/photos/about/hero-image.png 2x"
                alt="About"
                className={classes.image}
                lazyProps={{
                  width: '100%',
                  height: '100%',
                }}
            />
              <Section className={classes.section}>
                <SectionHeader
                  title={info.title}
                  subtitle={info.subTitle}
                  align="left"
                  data-aos="fade-up"
                  disableGutter
                  titleProps={{
                    className: clsx(classes.title, classes.textWhite),
                    variant: 'h3',
                  }}
                  subtitleProps={{
                    className: classes.textWhite,
                  }}
                />
              </Section>
            </header>
          )
        })}
      
    </div>
  );
};

Hero.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Hero;
